:root {
  --primary: #131D26;
  --secondary: #00AED7;
}
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@200;400;600;700&display=swap');
body{
  box-sizing: border-box;
  direction: rtl;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
}
html {
  scroll-behavior: smooth;
}
a{
  text-decoration: none;
  color: inherit;
}
ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
button{
  background-color: var(--secondary);
  height: 40px;
}
button:disabled {
  opacity: 0.5;
}

input:focus-visible, 
textarea:focus-visible {
  outline: none;
}

textarea::-webkit-resizer,
input::-webkit-inner-spin-button {
  display: none;
}

input {
  padding: 2px 5px 2px 5px;
}
input:disabled{
  border:  solid 1px gray !important;
}
textarea {
  padding: 1px 3px 1px 3px;
}
.radio{
  width: 25px !important;
  margin-left: 3px !important;
}
.text_english{
  direction: ltr !important;
}
/* GLOPAL CLASS */
.bg_primary{
  background-color: #131D26;
}
.bg_secondary{
  background-color: #00AED7;
}
.bg_danger{
  background-color: #EF4444;
}
.text_primary{
  color: black;
}
.text_secondary{
  color: #00AED7;
}
.my_btn{
  padding: 7px 20px !important;
  color: white;
  line-height: 100%;
}
.my_input{
  border: solid 2px var(--primary);
  border-radius: 4px;
}
.input{
  width: 370px;
  border: solid 2px var(--primary);
  border-radius: 4px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px 2px;
}
.my_border{
  border: solid 1px var(--primary);
  border-radius: 4px;
}
.my_rounded{
  border-radius: 4px;

}
.border_botom{
  border-bottom: solid 1px var(--secondary);
}
.dark_border{
  border: solid 1px darkgray;
  border-radius: 4px;
}
.box_shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.circle{
  border-radius: 50%;
}
.page_container{
  margin-right: 375px;
  padding: 0px 15px;
}
.position_center{
  left: 50%;
  transform: translate(-50%, 0);
}
.bg_cover{
  background-size: cover;
  background-repeat: no-repeat;
}
.text_very_small{
  font-size: 8px;
}
.position_center{
  transform: translate(-50%,-50%);
  left: 50%;
}
.pointer{
  cursor: pointer;
}
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid black;
  width: 20px;
  height: 20px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.Large_Loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid var(--secondary);
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.modal_escape_bg{
  background-color: #555;
}
.err_messsage{
  color: #EF4444 ;
  font-size: 12px;
  text-align: start;
  width: 100%;
  min-height:6px;
  max-height: 12px;
  margin: 5px 0px;
  max-width: 320px;
}
/* .scroler_hide::-webkit-scrollbar {
  display: none;
} */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px;
  width: 80px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.z_index_5{
  z-index: 5;
}
.modal_container{
  padding-right: 300px;
  position: fixed;
  top: 0px;
  left: 0px;
  /* margin-right: 288px; */
  z-index: 10;
  width: 100%;
  height: 100vh;
}
.modal_bg{
  background-color: #1E1E1E;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-right: 288px;
  height: 100vh;
  width: 100%;
  z-index: 9;
}
.esc_modal{
  width: 90%;
  height: 40px;
  margin:50px auto -2px;
  background-color: rgb(73, 69, 69);
  color: white;
  z-index: 10;
  position: relative;
}
.esc_modal button{
  background-color: #1E1E1E;
  color: white;
  height: 100%;
  text-align: start;
  padding-right: 20px;
  width: 200px;
  font-size: 25px;
  z-index: 12;
  position: relative;
}
.modal_content{
  background-color: white;
  z-index: 10;
  position: relative;
  border-radius: 8px;
  width: 90%;
  margin:0px auto ;
  padding: 25px 70px;
  overflow-y: scroll;
  height: 80vh;
}
.image_bg{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.text_black{
  color: black !important;
}
/* END GLOPAL CLASS */
/* side bar */
.sidebar_container ul{
  border-top: solid 2px white;
  margin-top: 20px !important; 
  width: 80%;
  margin: auto;
}
.sidebar_container ul li{
  font-size: 22px;
}
/* pages */
.page_title{
  font-size: 35px;
  font-weight: 600;
  border-bottom: solid 4px var(--primary);
  padding: 25px 0px 15px;
  width: 70%;
  margin-bottom: 15px;
}
.search_input {
  border: solid 2px darkgray;
  border-radius: 4px;
}
.activation_btn{
  width: 47px;
  height: 21px;
  position: relative;
  border-radius: 16px;
  margin: auto;
  background-color: var(--secondary);
}
.activation_btn .dot{
  width: 15px;
  height: 15px;
  background-color: aliceblue;
  position: absolute;
  left: 3px;
  top: 2.7px;
  border-radius: 50%;
}
.custom_table_container{
  height: 400px;
  overflow-y: scroll;
}
.activation_btn_none{
  width: 47px;
  height: 21px;
  background-color: darkgrey;
  position: relative;
  border-radius: 16px;
  margin: auto;
}
.activation_btn_none .dot{
  width: 15px;
  height: 15px;
  background-color: aliceblue;
  position: absolute;
  right: 3px;
  top: 2.7px;
  border-radius: 50%;
}
.star_active svg{
  color: #FFD700;
}
.star_not_active{
  color: rgb(215, 210, 210);
}
/* END PRODUCT DETAILES */
.product_progress{
  width: 70%;
  border-bottom: solid 2px darkgray;
  margin: 40px auto 0px auto;
}
.product_title{
  border-bottom: solid 2px darkgray;
  padding-bottom: 10px;
}
/* END PRODUCT DETAILES */
/* ADD FORMS PAGES */
.add_form{
  width: 100%;
  margin: 30px 0px;
}
.form_row{
  width:90%;
  border-bottom: solid 1px darkgray;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-top: 15px;
}
.add_form input,select,textarea{
  border: solid 2px var(--primary);
  border-radius: 4px;
  width: 370px;
  margin-top: 7px;
  padding: 3px 5px;
}
.add_form input:focus{
  border: solid 2px var(--secondary);
}
.add_form select:focus{
  border: solid 2px var(--secondary);
}
.add_form textarea:focus{
  border: solid 2px var(--secondary);
}
.add_form .add_img{
  width: 370px;
}
.add_form .add_img label{
  cursor: pointer;
}
.add_form .select_user_to_notefication{
  border-bottom: solid 1px darkgray;
  padding-bottom: 30px;
  width: 90%;
}
.add_form .select_user_to_notefication button,.add_form .select_user_to_notefication input{
  width: fit-content !important;
  padding: 8px 10px !important;
  margin-top: 0px !important;
  height: 33px;
}
.add_form .select_user_to_notefication .pagination_input{
  width: 40px !important;
  height: 35px !important;
}
.add_form .add_img .btn:hover{
  border:solid 2px var(--secondary) !important;
  color: var(--secondary);
}
.add_form button{
  width: 200px;
  padding: 10px 20px;
  /* background-color: #00AED7; */
  color: white;
  margin-top: 15px;
}
/* LOGIN PAGE */
.login_page figure{
  padding-bottom: 8px;
  border-bottom: solid 1px var(--primary);
}
/* colors modal */
.colors_modal_content{
  min-height: 500px;
  margin-top: 220px;
  width: 500px;
}
.close_add_color_btn{
  width: fit-content !important;
  color: #EF4444 !important;
}
.colors_modal_content ul{
  height: 300px;
  overflow-y: scroll;
}
.color_activation_Btn button{
  width: 40px;
}
.select_colors_container{
  justify-content: start;
  height: 300px;
  overflow-y: scroll;
  align-items: start;
}
.select_colors_container .color_element .delete_color{
  width: 20px !important;
  height: 35px !important;
  background-color: #EF4444;
  color: white;
  border-radius: 4px;
  line-height: 20px;
  text-align: center;
  /* margin: auto 2px 4px !important; */
  margin-bottom: 4px;
  margin-right: 2px;
}
.select_colors_container .version{
  height: 150px;
  overflow-y: scroll;
  width: 190px;
}
.select_colors_container select{
  width: 120px !important;
}
.select_colors_container .add_Sub_color{
  width: 120px !important;
  height: 40px;
  font-size: 12px;
  background-color: var(--secondary);
  line-height: 30px;
}
.select_colors_container .add_main_color{
  background-color:var(--secondary);
  width: 120px;
  height: 40px;
  font-size: 12px;
  margin-top: 7px;
}
.submit_product{
  background-color: var(--secondary) !important;
}
/* multianleselect */
.multiAble_Select_Input{
  width: 370px;
  display: flex;
  border: solid 2px var(--primary);
  overflow-y: scroll;
  height: 60px;
  border-radius: 4px;
  position: relative;
  flex-wrap: wrap;
  padding-left: 30px;
}
.open_list_icon{
  position: absolute;
  left: 2px;
  top: 3px;
  padding: 2px;
  height: 100%;
}
.open_list_icon:hover{
  color: var(--secondary);
}
.select_list{
  position: absolute;
  top: 60px !important;
  background-color: white;
  width: 370px;
  border: solid 1px var(--primary);
  padding: 3px;
  height: 200px;
  overflow-y: scroll;
}
.select_list li{
  padding: 3px 0px;
  cursor: pointer;
}
.select_list li:hover{
  color:var(--secondary);
}
.loader_container{
  width: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--primary);
  overflow-y: scroll;
  height: 60px;
  border-radius: 4px;
}
.selected_elem{
  width: fit-content;
  height: 40px;
  background-color: #89dfee;
  border-radius: 4px;
  padding-left: 8px;
  margin: 2px;
  display: flex;
  position: relative;
  padding: 2px;
}
.selected_elem .title{
  margin-left: 20px;
}
.selected_elem .esc{
  position: absolute;
  left: 3px;
  top: 0px;
}
.selected_elem .esc:hover{
  color: #EF4444;
  cursor: pointer;
}
